export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

/* a simple password sanity */
export const validatePassword = (password) => {
    return password.length >= 12;
}

/* return 2: strong password, 1: medium password, 0: weak password */
export const validateFullPassword = (password) => {
    let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    let mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
    if (strongRegex.test(password)) return 2;
    if (mediumRegex.test(password)) return 1;
    return 0;
}

export const validateNewDataInfo = (info) => {
    return info.length === 0 ? 'Entered text can not be empty' : '';
}

export const validateRequestReason = (reason) => {
    return (reason.length < 50 || reason.length > 1000) ? 'Reason is too short or too long. It should span from 50 to 1000 letters' : '';
}