import axios from 'axios';

const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;

/* axios request configs */
export const baseConfigs = {
    withCredentials: true, /* enable cookies. !important! */
    baseURL: apiEndPoint
}

/* add access token to headers if it exists in loggedUser */
export const getConfigs = (accessToken, isDownloading) => {
    let config = {}; /* make a copy */
    config.withCredentials = baseConfigs.withCredentials;
    config.baseURL = baseConfigs.baseURL;
    if (accessToken !== undefined) config.headers = {'token': accessToken};
    if (isDownloading) config.responseType = 'blob';
    return config;
}

/* verify cookies sent from user's browser. if valid, return the logged user. */
export const verifyCookie = (onSuccess, onFailure) => {
    axios.get('user/verifyCookie', baseConfigs).then(res => {
        if (res.data.code === 200) onSuccess(res.data.user); else onFailure(res.data.message);
    }).catch(errorMessage => onFailure(errorMessage));
}

export const login = (reqBody, onSuccess, onFailure) => {
    axios.post('user/login', reqBody, baseConfigs).then(res => {
        if (res.data.code === 200) {
          //  let user = [{id: 1, email: 'ip358@cam.ac.uk', name:'Ildi Pete', isadmin: true, datarequestallowed: true}];
            let user = res.data.user;
            if (res.data.user['postcogCookie'])
                user['accessToken'] = res.data.user['postcogCookie'];
            onSuccess(user);
        } else onFailure(res.data.message);
    }).catch(errorMessage => onFailure(errorMessage));
}
