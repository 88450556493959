import React, {StrictMode, useEffect} from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
// import 'shards-ui/dist/css/shards.min.css'
import './App.css';
import {Provider, useDispatch, useSelector} from 'react-redux';
import {createStore} from 'redux';
import rootReducer from './redux/reducers';
import Login from './login';
import {saveLoggedUser} from './redux/actions';
import {ClipLoader} from 'react-spinners';
import {verifyCookie} from './apis/datasets';


function App() {
    const loggedUser = useSelector(store => store.user.loggedUser);
    const dispatch = useDispatch();

    useEffect(() => {
        verifyCookie(loggedUser => { /* verify cookie in the first load */
            dispatch(saveLoggedUser(loggedUser)); /* cookie is valid, redirect to dashboard */
        }, errMsg => { /* cookie is invalid, requires login */
            dispatch(saveLoggedUser(null));
        })
    });

    let loading = <p style={{paddingTop: 300, textAlign: 'center'}}><ClipLoader size={60} color={'#2a72de'} loading/></p>
    return loggedUser === undefined ? loading : <Login/>;
}

ReactDOM.render(
    <StrictMode>
        <Provider store={createStore(rootReducer)}>
            <App/>
        </Provider>
    </StrictMode>,
    document.getElementById('root')
);
