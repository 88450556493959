import typeNames from './actionTypes';

export const saveLoggedUser = (loggedUser) => ({
    type: typeNames.SAVE_LOGGED_USER,
    data: {loggedUser: loggedUser},
});

export const saveRememberMe = (rememberMe) => ({
    type: typeNames.SAVE_REMEMBER_ME,
    data: {rememberMe: rememberMe},
});
