import React, {Fragment, useState} from 'react';
import {validateEmail, validatePassword} from './utils/validator';
import {login} from './apis/datasets'
import {Button, Form} from 'react-bootstrap';
import Footer from './footer';
import {saveRememberMe} from './redux/actions';
import {ClipLoader} from 'react-spinners';
import {useDispatch, useSelector} from 'react-redux';

export default function Login() {
    const rememberMe = useSelector(store => store.user.rememberMe);
    const dispatch = useDispatch();

    const [status, setStatus] = useState(0); /* 0: default, 1: waiting for server response, 2: success, redirecting */
    const [enteredEmail, setEnteredEmail] = useState('');
    const [enteredPassword, setEnteredPassword] = useState('');
    const [emailValidation, setEmailValidation] = useState('');
    const [passwordValidation, setPasswordValidation] = useState('');
    const [respondedMessage, setRespondedMessage] = useState('');

    let fieldsNotEmpty = enteredEmail !== '' && enteredPassword !== '';
    let fieldsValidated = emailValidation === '' && passwordValidation === '';
    let isNotDisable = fieldsNotEmpty && fieldsValidated && status === 0;

    let loginText = <span>Sign in</span>;
    let waitingText = <span>Loading &nbsp;<ClipLoader size={12} color={'#ffffff'} loading/></span>;
    let successText = <span>Signed in &nbsp;<ClipLoader size={12} color={'#ffffff'} loading/></span>;
    let loginButtonText = status === 0 ? loginText : (status === 1 ? waitingText : successText);
    let loginButtonTheme = status === 0 ? 'primary' : (status === 1 ? 'secondary' : 'success');

    return (
        <Fragment>
            <div class="d-flex align-items-center" style={{width: '100%', minHeight: '100%'}}>
                <main class="form-signin w-100 m-auto" style={{maxWidth: 330}}>
                    <form>
                        <img class="mb-4" src='/postcog.svg' alt={'Postcog Logo'} style={{height: 60}}/>
                        <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

                        <div className={"form-floating " + (emailValidation === '' ? '' : 'is-invalid')}>
                            <Form.Control type='email' onChange={(e) => {
                                setEmailValidation(validateEmail(e.target.value) ? '' : 'Email is invalid. Please try again.');
                                setEnteredEmail(e.target.value);
                                setRespondedMessage('');
                            }} disabled={status !== 0} className={"form-control " + (emailValidation === '' ? '' : 'is-invalid')} id="floatingInput" placeholder='name@example.com' style={{borderBottomRightRadius: 0, borderBottomLeftRadius: 0, marginBottom: -1}}/>
                            <label for="floatingInput">Email address</label>
                        </div>
                        <div className={"form-floating " + (passwordValidation === '' ? '' : 'is-invalid')}>
                            <Form.Control type='password' onChange={(e) => {
                                setPasswordValidation(validatePassword(e.target.value) ? '' : 'Weak password. Did you enter it correctly?');
                                setEnteredPassword(e.target.value);
                                setRespondedMessage('');
                            }} disabled={status !== 0} className={"form-control " + (passwordValidation === '' ? '' : 'is-invalid')} id="floatingPassword" placeholder='Password' style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}}/>
                            <label for="floatingPassword">Password</label>
                        </div>

                        <div className={(respondedMessage === '' ? '' : 'is-invalid')}></div>

                        <div id="validationText" class="invalid-feedback">
                            {emailValidation} {passwordValidation} {respondedMessage} {(emailValidation === '' && passwordValidation === '' && respondedMessage === '') ? '' : ''}
                        </div>

                        <div class="form-check text-start my-3">
                            <Form.Check type="checkbox" onChange={() => dispatch(saveRememberMe(!rememberMe))} checked={rememberMe} id="flexCheckDefault"/>
                            <label class="form-check-label" for="flexCheckDefault">
                                Remember me
                            </label>
                        </div>
                        <Button type='submit' pill theme={loginButtonTheme} className='btn btn-primary w-100 py-2' disabled={!isNotDisable} onClick={(e) => {
                            e.preventDefault();
                            setStatus(1);
                            login({email: enteredEmail, password: enteredPassword, rememberMe: rememberMe}, (u) => {
                                setStatus(2);
                                u['enableModal'] = true;
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1);
                            }, (errorMessage) => {
                                setStatus(0);
                                setRespondedMessage(errorMessage);
                            })
                        }}>{loginButtonText}
                        </Button>
                        
                        <p class="mt-5 mb-3 text-body-secondary"><Footer institution={'Cambridge Cybercrime Centre'}/></p>
                    </form>
                </main>
            </div>
        </Fragment>
    );
}
