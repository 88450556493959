import typeNames from '../actionTypes';

const initialState = {
    loggedUser: undefined, /* undefined -> loading, null -> login, otherwise -> dashboard */
    rememberMe: true
};

export default (state = initialState, action) => {
    if (action.type === typeNames.SAVE_LOGGED_USER) {
        const payload = action.data;
        console.log('Stored logged user into Redux', payload.loggedUser);
        return {...state, loggedUser: payload.loggedUser}
    }
    if (action.type === typeNames.SAVE_REMEMBER_ME) {
        const data = action.data;
        console.log('Stored remember me into Redux', data.rememberMe);
        return {...state, rememberMe: data.rememberMe}
    }
    return state;
}